import { useQuery, useMutation } from 'react-query'
import { pathOr } from 'ramda'
import { UserColumns } from './auth.types'
import { request, MutationOptions, QueryVariables, Mutation } from '@shared/innmaxLib/services'

export const updateUserColumnSettingAPI = (body: any) => {
  return request('/memberInfo/columnMap', { method: 'POST', body })
}

export const useUserColumns = ({
  variables,
  ...options
}: QueryVariables = {}) => {
  const { data, isLoading, ...others } = useQuery(
    ['/memberInfo/columnMap', variables],
    {
      ...options,
      cacheTime: 60000,
      select: res => pathOr<UserColumns>({}, ['data'], res)
    }
  )

  return {
    data,
    personalColumn: data,
    userColumns: data,
    checking: isLoading,
    loading: isLoading,
    ...others,
  }
}

export function useUpdateUserColumn(options: MutationOptions) {
  const { mutate, isLoading } = useMutation(updateUserColumnSettingAPI, options)

  return [isLoading, mutate] as Mutation
}
