import React from 'react'
import { renderToString } from 'react-dom/server'
import { Layout, Button } from 'antd'
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { useAuth } from '@services/auth'
import { usePageHeader } from '@shared/innmaxLib/hooks/usePageHeader'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@shared/innmaxUI/Dropdown'

type Props = {
  title?: string | React.ReactNode
}

function Navbar({ title }: Props) {
  const { logout, user } = useAuth()

  const handleLogout = () => logout()

  const { t } = useTranslation()

  React.useEffect(() => {
    (document.getElementById('header-title') as HTMLElement).innerHTML =
      renderToString(title as React.ReactElement<React.ReactNode>)
  }, [JSON.stringify((title as any)?.props)]) //eslint-disable-line

  return (
    <Header>
      <div id="header-title"/>
      <div className="inline-flex items-center">
        {usePageHeader().content}
        <Dropdown
          menu={[
            {
              key: "SignOut",
              label: t('common:logout'),
              onClick: handleLogout,
              style: { textAlign: 'center' }
            },
          ]}>
          <Button type="link" className="ant-dropdown-link p-0">
            <LoginUser>
              <span>{pathOr('', ['displayName'], user)}</span>
              <CaretDownOutlined className="icon-caret-down" />
            </LoginUser>
          </Button>
        </Dropdown>
      </div>
    </Header>
  )
}

const Header = styled(Layout.Header)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &.ant-layout-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 16px;
    background: transparent;
    line-height: initial;
  }
`

const LoginUser = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.font.secondary};
  font-size: 14px;
  font-weight: normal;

  .icon-caret-down {
    position: relative;
    top: 2px;
    margin-left: 4px !important;
    font-size: 14px !important;
  }
`

export default Navbar
