import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { path, uniqBy, pipe, filter, isNil } from 'ramda'
import {
  convertData,
  DataSource,
  QueryVariables,
  useLazyQuery,
  MutationOptions,
} from '@shared/innmaxLib/services'
import {
  request,
  useMakeMutation,
  useExternalQuery,
} from '@shared/innmaxLib/services'

import {
  Device,
  useDeviceConverter,
  useConvertIDevice,
  useLazySubDevice,
} from '@services/device'

const { compose } = require('ramda')

export const useQueryMultipleDevice = () => {
  const convertDevice = useDeviceConverter()

  const fetchMultipleDevice = (devices = []) => {
    const tasks = pipe(
      filter((d: any) => d && !isNil(d.deviceType) && !isNil(d.id)),
      uniqBy((d: any) => d.id && d.deviceType)
    )(devices).map((d: any) => request(`/device/${d.deviceType}/${d.id}`))
    return Promise.all(tasks).then(result => {
      return result.map(r => convertDevice(path(['data'], r) as any))
    })
  }

  return {
    fetchMultipleDevice,
  }
}

export const useLazyQueryDevice = (options: QueryVariables = {}) => {
  const convertDevice = useDeviceConverter()

  const {
    isLoading: isFetchingSubDevice,
    refetch: fetchSubDevice,
    data: subDevice,
  } = useLazySubDevice(undefined)

  const { refetch, data, ...rest } = useLazyQuery('/device/{deviceType}/{id}', {
    ...options,
    select: res => path(['data'], res),
    keepPreviousData: true,
    onSuccess: (device: any) => {
      device &&
        device.subDeviceCount &&
        fetchSubDevice({
          parentDeviceSeq: device?.id,
          parentDeviceType: device?.deviceType,
        })
      return options.onSuccess && options.onSuccess(convertDevice(device))
    },
  })

  const device: Device | any = React.useMemo(() => (data ? data : null), [data]) //eslint-disable-line

  const subDevices: Device | unknown = React.useMemo(
    () => (data ? subDevice : null),
    [subDevice] //eslint-disable-line
  )

  const dataSource = React.useMemo(
    () =>
      data
        ? compose(convertDevice)({
            ...device,
            ...(device.subDeviceCount ? { subDevices } : null),
          })
        : null,
    [subDevices, device] //eslint-disable-line
  )

  return {
    ...rest,
    data: dataSource,
    loading: rest.isLoading || isFetchingSubDevice,
    loadingData: rest.isLoading || isFetchingSubDevice,
    queryDevice: refetch,
  }
}

export const useDeviceInfos = <T extends Device>({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const convertDevice = useDeviceConverter()

  const { data, refetch, ...others } = useLazyQuery(
    [
      '/device/sampleDeviceInfos',
      { current: '', size: '', isSub: false, ...variables },
    ],
    {
      ...options,
      keepPreviousData: true,
    }
  )

  const dataSource: DataSource<T> = React.useMemo(
    () =>
      convertData<T>(
        {
          converter: convertDevice,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  const devicesBySessionId = React.useMemo(() => {
    const area: any[] = []

    dataSource?.content
      ?.filter((d: any) => d.lat && d.lon)
      .forEach(i => {
        if (i.sessionPath.length > 2) {
          if (!area[i.sessionId as number]) {
            area[i.sessionId as number] = []
          }
          area[i.sessionId as number].push(i)
        }

        const sencondSessionId = i?.sessionPath[1]?.id
        if (sencondSessionId) {
          if (!area[sencondSessionId as number]) {
            area[sencondSessionId as number] = []
          }
          area[sencondSessionId as number].push(i)
        }
      })

    return area
  }, [dataSource])

  return {
    ...others,
    refetch,
    loading: others.isLoading || !others.dataUpdatedAt,
    queryDeviceInfos: refetch,
    dataSource: dataSource?.content || [],
    devicesBySessionId,
  }
}

/**
 *  設備清單
 * @param deep:是否搜尋區域深層
 */
export const useDevices = <T extends Device>(
  query?: {
    [key: string]: any
  },
  options?: UseQueryOptions
) => {
  const convertDevice = useDeviceConverter()

  const { data, ...rest } = useQuery(
    ['/device/{deviceType}/{pageNum}/{pageSize}', { deep: true, ...query }],
    { cacheTime: 0, ...options }
  )

  const dataSource: DataSource<T> = React.useMemo(
    () =>
      convertData<T>(
        {
          converter: convertDevice,
        },
        data
      ),
    [data] //eslint-disable-line
  )

  return {
    ...rest,
    dataSource,
    data,
    loading: rest.isLoading,
  }
}

export const useDevice = ({
  deviceType,
  id,
}: {
  deviceType: string
  id?: React.Key
}) => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/device/{deviceType}/{id}`, { deviceType, id }],
    { enabled: !!id, cacheTime: 0, select: res => path(['data'], res) }
  )

  const { data: deviceData } = useConvertIDevice(data)

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: deviceData,
  }
}

export const createDeviceUrl = '/device/{deviceType}'

export const createDevice = (values: Partial<any>) =>
  request(createDeviceUrl, { method: 'POST', body: values })

export const useAddDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(createDevice, options)
}

export const deleteDevice = (values: { deviceType: string; id: React.Key }) =>
  request(`/device/{deviceType}/{id}`, { method: 'DELETE', body: values })

export const updateDeviceUrl = '/device/{deviceType}/{id}'

export const updateDevice = (values: Partial<any> = {}) =>
  request(updateDeviceUrl, {
    method: 'PUT',
    body: values,
  })

export const useUpdateDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(updateDevice, options)
}

export const useDeleteDevice = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteDevice, options)
}

export const useDeviceExport = (query?: any, options?: QueryVariables) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    ['/device/{deviceType}/export', { ...query }],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}

export const useSLGESCRealtimeStatus = () => {
  return useExternalQuery({
    key: 'RealtimeStatus',
    url: 'https://www.slgesc.nat.gov.tw/RealtimeStatus',
  })
}

export const useSLGESCPowerMonthSummary = ({ year, month }: any) => {
  return useExternalQuery({
    key: 'PowerMonthSummary',
    url: `https://www.slgesc.nat.gov.tw/PowerMonthSummary/${year}/${month}`,
  })
}

export const useSLGESCWeatherReport = () => {
  return useExternalQuery({
    key: 'WeatherReport',
    url: 'https://www.slgesc.nat.gov.tw/WeatherReport',
  })
}

// for testing
// export const usePublicDevice = ({ deviceType, deviceId }: any) => {
//   return useExternalQuery({
//     key: 'PublicDevice',
//     url: `https://testing2.f2fcloud.com/SCTainanShalun_3_0/api/v1/public/device/${deviceType}/byDeviceId/${deviceId}`,
//   })
// }

export const usePublicDevice = (
  query?: {
    [key: string]: any
  },
  options?: UseQueryOptions
): any => {
  const { data, isLoading, ...rest } = useQuery(
    [
      '/public/device/{deviceType}/byDeviceId/{deviceId}',
      { deep: true, ...query },
    ],
    { cacheTime: 0, ...options }
  )

  return {
    ...rest,
    data,
    isLoading,
  }
}
