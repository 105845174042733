import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import commonEN from './en/common.json'
import commonZhTW from './zhTW/common.json'
import lightEN from './en/light.json'
import lightZhTW from './zhTW/light.json'
import lampCtlerEN from './en/lampCtler.json'
import lampCtlerZhTW from './zhTW/lampCtler.json'
import menuEN from './en/menu.json'
import menuZhTW from './zhTW/menu.json'
import lightScheduleEN from './en/lightSchedule.json'
import lightScheduleZhTW from './zhTW/lightSchedule.json'
import lightGroupEN from './en/lightGroup.json'
import lightGroupZhTW from './zhTW/lightGroup.json'
import repairEN from './en/repair.json'
import repairZhTW from './zhTW/repair.json'
import userEN from './en/user.json'
import userZhTW from './zhTW/user.json'
import systemEN from './en/system.json'
import systemZhTW from './zhTW/system.json'
import dashboardEN from './en/dashboard.json'
import dashboardZhTW from './zhTW/dashboard.json'
import reportEN from './en/report.json'
import reportZhTW from './zhTW/report.json'
import deviceEN from './en/device.json'
import deviceZhTW from './zhTW/device.json'
import camreaEN from './en/camera.json'
import cameraZhTW from './zhTW/camera.json'
import visualdoorbellEN from './en/visualdoorbell.json'
import visualdoorbellZhTW from './zhTW/visualdoorbell.json'
import switchboxEN from './en/switchbox.json'
import switchboxZhTW from './zhTW/switchbox.json'
import sessionEN from './en/session.json'
import sessionZhTW from './zhTW/session.json'
import waterlevelEN from './en/waterlevel.json'
import waterlevelZhTW from './zhTW/waterlevel.json'
import mediaContentEN from './en/mediaContent.json'
import mediaContentZhTW from './zhTW/mediaContent.json'

import mobileEN from './en/mobile.json'
import mobileZhTW from './zhTW/mobile.json'

import displayscreenZhTW from './zhTW/displayscreen.json'
import displayscreenEN from './en/displayscreen.json'

import powersupplierZhTW from './zhTW/powersupplier.json'
import aiboxZhTW from './zhTW/aibox.json'

import weatherstationZhTw from './zhTW/weatherstation.json'

import repairerZhTw from './zhTW/repairer.json'

import optionZhTw from './zhTW/option.json'

import lineZhTw from './zhTW/line.json'

// the translations
const resources = {
  en: {
    common: commonEN,
    menu: menuEN,
    lampCtler: lampCtlerEN,
    streetlight: lightEN,
    light: lightEN,
    schedule: lightScheduleEN,
    lightGroup: lightGroupEN,
    repair: repairEN,
    user: userEN,
    system: systemEN,
    dashboard: dashboardEN,
    report: reportEN,
    mobile: mobileEN,
    device: deviceEN,
    camera: camreaEN,
    visualdoorbell: visualdoorbellEN,
    switchbox: switchboxEN,
    session: sessionEN,
    waterlevel: waterlevelEN,
    mediaContent: mediaContentEN,
    displayscreen: displayscreenEN,
    powersupplier: powersupplierZhTW,
    aibox: aiboxZhTW,
    weatherstation: weatherstationZhTw,
    repairer: repairerZhTw,
    option: optionZhTw,
    line: lineZhTw,
  },
  'zh-TW': {
    menu: menuZhTW,
    common: commonZhTW,
    lampCtler: lampCtlerZhTW,
    streetlight: lightZhTW,
    light: lightZhTW,
    schedule: lightScheduleZhTW,
    lightGroup: lightGroupZhTW,
    repair: repairZhTW,
    user: userZhTW,
    system: systemZhTW,
    dashboard: dashboardZhTW,
    report: reportZhTW,
    mobile: mobileZhTW,
    device: deviceZhTW,
    camera: cameraZhTW,
    visualdoorbell: visualdoorbellZhTW,
    switchbox: switchboxZhTW,
    session: sessionZhTW,
    waterlevel: waterlevelZhTW,
    mediaContent: mediaContentZhTW,
    displayscreen: displayscreenZhTW,
    powersupplier: powersupplierZhTW,
    aibox: aiboxZhTW,
    weatherstation: weatherstationZhTw,
    repairer: repairerZhTw,
    option: optionZhTw,
    line: lineZhTw,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: [
      'common',
      'light',
      'lampCtler',
      'schedule',
      'lightGroup',
      'repair',
      'user',
      'system',
      'dashboard',
      'report',
      'mobile',
      'device',
      'camera',
      'visualdoorbell',
      'switchbox',
      'session',
      'mediaContent',
      'powersupplier',
      'aibox',
      'weatherstation',
      'repairer',
      'option',
      'line',
    ],
    // defaultNS: 'moduleA',
    // fallbackNS: ['common'],
    resources,
    lng: 'zh-TW',
    fallbackLng: ['zh-TW', 'en'],
    keySeparator: false, // we do not use keys in form messages.welcome
  })

export default i18n
