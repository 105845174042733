import { TableProps } from './table.type'
import MobileTable from './MobileTable'
import QueryTable from './QueryTable'
import React from 'react'

export { default as QueryTable } from './QueryTable'
export { default as BaseTable } from './Table'
export { default as ReportTable } from './ReportTable'

export { EditableCell, EditableRow } from './EditableTableContext'

export { default as EditableTable } from './EditableTable'

export type { RowAddHandler, RowDeleteHandler } from './EditableTable'

export type { EditableCellProps, EditableRowProps } from './EditableTableContext'

function TableFC<T = any>(props: TableProps<T>) {
  const [tableMode, setTableMode] = React.useState<'mobile' | 'desktop'>('desktop')

  React.useEffect(() => {
    const detectTableModel = () => {
      const breakPoints = 768
      if (window.innerWidth < breakPoints) {
        setTableMode('mobile')
      } else {
        setTableMode('desktop')
      }
    }
    detectTableModel()
    window.addEventListener('resize', detectTableModel)
    return () => {
      window.removeEventListener('resize', detectTableModel)
    }
  }, [])

  return tableMode === 'desktop' ? <QueryTable {...props} /> : <MobileTable {...props} />
}

const Table = React.memo(TableFC) as typeof TableFC

export default Table
